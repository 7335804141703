import { MARKETPLACE_URL } from 'src/api/axios/api'

export enum SurveyType {
  DigitalSellerSurvey = 'Digital Seller Survey',
}

const surveyPrefix = 'survey.'

export const SurveyGetSurvey = `${MARKETPLACE_URL}/${surveyPrefix}getSurvey`
export const SurveyGetSurveyDetail = `${MARKETPLACE_URL}/${surveyPrefix}getSurveyDetail`
