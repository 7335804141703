import { ThemeOptions } from '@material-ui/core'
import { Colors } from 'everchain-uilibrary'

export const defaultFontFamily = 'Roboto'
export const defaultLabelFontSize = '11px'
export const defaultValueFontSize = '13px'
export const defaultTitleFontSize = '15px'
export const defaultTitleFontHeight = 700
export const defaultTitleLetterSpaceing = '0.3px'

interface ThemeDefaultOptions extends ThemeOptions {
  debtColors: {
    white: string
    error: string
    success: string
  }
}

const defaultTheme: ThemeDefaultOptions = {
  palette: {
    common: {
      black: Colors.primary,
    },
    primary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.primary,
      contrastText: Colors.white,
    },
  },
  debtColors: {
    white: Colors.white,
    error: Colors.error,
    success: Colors.success,
  },
  typography: {
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {
      fontSize: 24,
      fontWeight: 400,
    },
    h2: {
      color: Colors.primary,
      fontSize: 18,
      fontWeight: 500,
    },
  },
  spacing: 4,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          backgroundColor: Colors.white,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: Colors.primary,
        color: '#222',
      },
    },
    MuiBadge: {
      colorPrimary: {
        backgroundColor: Colors.white,
        color: Colors.primary,
      },
    },
    MuiButton: {
      text: {
        textTransform: 'none',
        fontWeight: 400,
      },
      contained: {
        textTransform: 'none',
        fontWeight: 400,
      },
      outlined: {
        fontWeight: 400,
        textTransform: 'none',
      },
    },
    MuiTypography: {
      root: {
        fontSize: '0.9rem',
        lineHeight: '1rem',
        textTransform: 'none',
        color: Colors.black,
      },
      body1: { fontSize: '0.9rem' },
      body2: { fontSize: '0.9rem' },
      subtitle1: { fontSize: '1.5rem' },
      subtitle2: { fontWeight: 700 },
      h6: { fontSize: '1rem' },
    },
    MuiInputBase: {
      root: { fontSize: '0.9rem' },
    },
    MuiFormLabel: {
      root: { fontSize: '0.9rem' },
    },
    MuiTableCell: {
      root: { fontSize: '0.9rem' },
    },
    MuiTooltip: {
      tooltip: { fontSize: '0.75rem' },
    },
    MuiMenuItem: {
      root: { fontSize: '0.9rem' },
    },
  },
}

export default defaultTheme
