import { AppBar, Box, Footer, Main, MainContent } from 'everchain-uilibrary'
import React, { useContext } from 'react'
import { AuthContext, useAuth } from 'src/context/AuthenticationContext'

import { APP_NAME } from 'src/utils/constants'
import { isUkCountry } from 'src/utils/common'
import Menus from './Menus'
import { Root } from './styles'

interface Props {
  children: React.ReactNode
}

const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const authenticationObj = useAuth()
  const userId = window.localStorage.getItem('userId')
  const { origin } = window.location
  const changePasswordlink = `${process.env.REACT_APP_AUTHORITY_URL}/account/ChangePassword?userid=${userId}&returnurl=${origin}`
  const { profileClient } = useContext(AuthContext)
  const isUk = isUkCountry(
    profileClient?.Country || process.env.REACT_APP_COUNTRY
  )

  const handleChangepassword = (): void => {
    window.open(changePasswordlink, '_blank')
  }

  const handleOnLogout = (): void => {
    authenticationObj.logout()
  }

  return (
    <Root>
      <AppBar
        appName={APP_NAME}
        logoutProps={{ onClickLogout: handleOnLogout }}
        userProps={{
          userName:
            authenticationObj.user?.profile[
              'http://debttrader.com/claims/display_name'
            ],
          onClickChangePassword: handleChangepassword,
        }}
      />
      <Box display="flex" flexDirection="row" height="inherit">
        <Menus />
        <Main>
          <MainContent>{children}</MainContent>
          <Footer isUk={isUk} />
        </Main>
      </Box>
    </Root>
  )
}

export default MainLayout
