import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import Loader from 'src/components/Loader'
import {
  GridCellProps,
  GridColumn as KendoGridColumn,
} from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import SectionCard from 'src/components/SectionCard'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { State } from '@progress/kendo-data-query'
import { Archive, Close, GetApp } from '@material-ui/icons'
import { ExcelExport } from '@progress/kendo-react-excel-export'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { DialogTitleStyled } from 'src/components/Dialogs/style'
import UploadSOP from 'src/components/UploadSOP'
import { AuthContext } from 'src/context/AuthenticationContext'
import { AbilityContext } from 'src/context/Can'
import { PermissionCodeAccess } from 'src/utils/constants'
import { archiveSOPFile } from 'src/api/operations/post/sop'
import { renderDate } from 'src/utils/formatKendoColumns'
import { Content } from 'everchain-uilibrary'
import { getColumns } from './CorporateSOPColumns'
import DownloadSOP from './DownloadSOP'

const CorporateSOP: React.FC = () => {
  const [cardType, setCardType] = useState('master')
  const userToken = GetAccessToken()
  const grid = useRef<any>(null)
  const [columns, setColumns] = useState<any[]>([])
  let exportedData: any
  const queryClient = useQueryClient()
  const { userPermissions } = useContext(AuthContext)

  const isInternal = userPermissions.type.toLowerCase() === 'internal'
  const ability = useContext(AbilityContext)
  const [sopIds, setSopIds] = useState('')

  const [openLoadSOP, setOpenLoadSOP] = useState(false)

  const handleClose = () => {
    queryClient.refetchQueries({
      queryKey: 'getCorporateSOPCards',
    })
    queryClient.refetchQueries({
      queryKey: 'getCorporateSOPGrid',
    })
    setOpenLoadSOP(false)
  }

  const { enqueueSnackbar } = useSnackbar()
  const notifyError = notistackOptions('error')

  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })

  const exportData = (dataToExport: any) => {
    if (dataToExport && dataToExport.length > 0) {
      exportedData.save(dataToExport, columns)
    } else {
      enqueueSnackbar('There is no data to be exported', notifyError)
    }
  }

  const [showArchived, setShowArchived] = useState<boolean>(
    JSON.parse(window.localStorage.getItem('showArchived') || 'false')
  )
  const getCorporateSOPCards = useQuery({
    queryKey: ['getCorporateSOPCards', showArchived],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOPCards`,
        params: { includeArchived: showArchived, ids: sopIds },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const corporateCardData = getCorporateSOPCards?.data || []

  const getCorporateSOPGrid = useQuery({
    enabled: cardType !== '',
    queryKey: ['getCorporateSOPGrid', cardType, gridState, showArchived],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/corporateSOP/getCorporateSOPGrid`,
        params: {
          cardType,
          pagination: encodeURIComponent(JSON.stringify(gridState)),
          includeArchived: showArchived,
          ids: sopIds,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const [filesToDownload, setFilesToDownload] = useState<any[]>([])

  const [fileToArchive, setFileToArchive] = useState('')
  const [archiveReason, setArchiveReason] = useState<string>('')

  const mutationArchiveSOP = useMutation({
    mutationFn: async (id: string) => {
      await archiveSOPFile(id, archiveReason).then(() => {
        queryClient.refetchQueries({
          queryKey: 'getCorporateSOPGrid',
        })
        queryClient.refetchQueries({
          queryKey: 'getCorporateSOPCards',
        })
      })
      setFileToArchive('')
      setArchiveReason('')
      enqueueSnackbar('SOP has been archived.', notistackOptions('success'))
    },
  })

  const corporateGridData = getCorporateSOPGrid?.data || []

  const handleCardType = (card: string) => {
    setCardType(card)
  }

  const loading =
    getCorporateSOPCards.isLoading || getCorporateSOPGrid.isLoading

  useEffect(() => {
    if (cardType !== '') setColumns(getColumns(cardType))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardType])

  const handleFilesChange = (e: any) => {
    if (filesToDownload && filesToDownload.includes(e)) {
      setFilesToDownload(filesToDownload.filter((f) => f !== e))
    } else {
      setFilesToDownload(filesToDownload?.concat(e))
    }
  }

  const [error, setError] = useState('')

  const handleSopIdsChange = (e: any) => {
    const input = e.target.value
    // Regex to allow only numbers and commas, no consecutive commas

    if (/^(\d+(,\d+)*)?$/.test(input)) {
      setSopIds(input)
      setError('')
    } else if (/,$/.test(input) && !input.includes(',,')) {
      setSopIds(input)
      setError('')
    }
  }

  const handleFilter = () => {
    getCorporateSOPCards.refetch()
    getCorporateSOPGrid.refetch()
  }

  return (
    <>
      <Content>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Corporate SOP</Typography>
          </Grid>
        </Grid>

        <Box mt={10} style={{ justifyContent: 'center' }}>
          {corporateCardData.isLoading && <Loader />}
          <Grid container style={{ justifyContent: 'center' }}>
            {cardType !== '' && !corporateCardData.isLoading && (
              <Grid item xs={12} md={12}>
                <SectionCard
                  cards={corporateCardData || []}
                  onCardClick={handleCardType}
                  selected={cardType}
                />
              </Grid>
            )}
          </Grid>
        </Box>
        <Box mt={10}>
          <ExcelExport
            data={corporateGridData?.files}
            fileName={`${cardType}.xlsx`}
            // eslint-disable-next-line no-return-assign
            ref={(exporter) => (exportedData = exporter)}
          >
            <Box>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box display="flex" flexDirection="row">
                  <Box display="flex" flexDirection="row">
                    <Typography component="div">
                      <Grid
                        data-cy="dialog-switch"
                        component="label"
                        container
                        alignItems="center"
                        spacing={1}
                      >
                        <Grid item>Show Archived SOP</Grid>
                        <Grid item>
                          <Switch
                            checked={showArchived}
                            onChange={() => {
                              window.localStorage.setItem(
                                'showArchived',
                                JSON.stringify(!showArchived)
                              )
                              setShowArchived(!showArchived)
                            }}
                            name="switchShowArchived"
                            title="Show Archived SOP"
                          />
                        </Grid>
                      </Grid>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="row" ml={3}>
                    <Grid
                      data-cy="item-sopid"
                      component="label"
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography>SOP IDs:</Typography>
                      </Grid>
                      <Grid item>
                        <TextField
                          fullWidth
                          inputProps={{ maxLength: 100 }}
                          style={{ width: '400px' }}
                          name="sopIds"
                          value={sopIds}
                          onChange={handleSopIdsChange}
                          error={!!error}
                          helperText={error}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box ml={3} display="flex" flexDirection="row">
                    <div>
                      <Button
                        title="Filter"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          if (!(sopIds.charAt(sopIds.length - 1) === ',')) {
                            handleFilter()
                          } else setError('Invalid format. ')
                        }}
                      >
                        Filter
                      </Button>
                    </div>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="right">
                  {cardType === 'master' && (
                    <DownloadSOP
                      files={filesToDownload || []}
                      includeArchived={false}
                    />
                  )}
                  {isInternal &&
                    ability.can(
                      PermissionCodeAccess.ComplianceManagementCorporateSOP_Upload,
                      'any'
                    ) && (
                      <div>
                        <Button
                          style={{ marginRight: 5, marginLeft: 5 }}
                          data-cy="load-sop-button"
                          variant="contained"
                          color="primary"
                          title="Upload SOP"
                          onClick={() => setOpenLoadSOP(true)}
                        >
                          Upload SOP
                        </Button>
                      </div>
                    )}
                  <div>
                    <Button
                      title="Export Excel"
                      variant="contained"
                      color="primary"
                      onClick={() => exportData(corporateGridData.files)}
                      startIcon={<GetApp />}
                      disabled={!corporateGridData}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </Box>
              </Box>

              {loading && <Loader />}
              {cardType !== '' && (
                <Box mt={2}>
                  <KendoGrid
                    ref={grid}
                    scrollable="scrollable"
                    data={corporateGridData?.files ?? []}
                    skip={gridState.skip}
                    take={gridState.take}
                    pageSize={gridState.take}
                    filter={gridState.filter}
                    sort={gridState.sort}
                    sortable
                    pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
                    total={corporateGridData?.total ?? 0}
                    onDataStateChange={(e) => {
                      setGridState(e.dataState)
                    }}
                    style={{ minHeight: '200px', maxHeight: '500px' }}
                  >
                    {cardType === 'master' && (
                      <KendoGridColumn
                        field="id"
                        title=" "
                        width="60"
                        cell={(props: GridCellProps) => {
                          return (
                            <td>
                              <Checkbox
                                data-cy="id-checkbox"
                                checked={filesToDownload?.includes(
                                  props.dataItem['id']
                                )}
                                onChange={() => {
                                  handleFilesChange(props.dataItem['id'])
                                }}
                                name="id-checkbox"
                                color="primary"
                              />
                            </td>
                          )
                        }}
                      />
                    )}
                    {columns.reduce((acc, item, idx) => {
                      const component = [...acc]

                      item.show &&
                        component.push(
                          <KendoGridColumn
                            key={item.field + item.title}
                            field={item.field}
                            title={item.title}
                            cell={item.cell}
                            width={item.width}
                            columnMenu={ColumnMenu}
                          />
                        )

                      return component
                    }, [])}

                    {cardType === 'master' && (
                      <KendoGridColumn
                        field="archivedDate"
                        title="Archive"
                        width="100"
                        cell={(props: GridCellProps) => {
                          return !props.dataItem['archivedDate'] ? (
                            <td className="k-command-cell">
                              <Tooltip title="Archive">
                                <span>
                                  <IconButton
                                    disabled={
                                      props.dataItem['archivedDate'] ||
                                      mutationArchiveSOP.isLoading
                                    }
                                    color="primary"
                                    aria-label="Archive"
                                    onClick={
                                      () =>
                                        setFileToArchive(props.dataItem['id'])
                                      // eslint-disable-next-line react/jsx-curly-newline
                                    }
                                  >
                                    <Archive />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            </td>
                          ) : (
                            renderDate(props)
                          )
                        }}
                      />
                    )}
                  </KendoGrid>
                </Box>
              )}
            </Box>
          </ExcelExport>
        </Box>
      </Content>
      {openLoadSOP && (
        <Dialog
          open={openLoadSOP}
          onClose={() => setOpenLoadSOP(false)}
          aria-labelledby="dialog-load-sop"
          aria-describedby="dialog-load-sop"
          maxWidth="md"
        >
          <DialogTitleStyled id="dialog-load-sop">
            <Typography
              variant="body1"
              style={{
                color: 'white',
                fontSize: '1.5rem',
                fontWeight: 400,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Load SOP
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => setOpenLoadSOP(false)}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <UploadSOP close={handleClose} />
          </DialogContent>
        </Dialog>
      )}

      {fileToArchive !== '' && (
        <Dialog
          open={fileToArchive !== ''}
          onClose={() => {
            setFileToArchive('')
            setArchiveReason('')
          }}
          aria-labelledby="dialog-archive-sop"
          aria-describedby="dialog-archive-sop"
          maxWidth="md"
        >
          <DialogTitleStyled id="dialog-archive-sop">
            <Typography
              variant="body1"
              style={{
                color: 'white',
                fontSize: '1.5rem',
                fontWeight: 400,
                lineHeight: 1.2,
                textAlign: 'center',
              }}
            >
              Archive SOP Reason
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => {
                setFileToArchive('')
                setArchiveReason('')
              }}
              style={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white',
              }}
            >
              <Close />
            </IconButton>
          </DialogTitleStyled>
          <DialogContent>
            <TextField
              multiline={true}
              minRows={5}
              variant="outlined"
              fullWidth
              inputProps={{ maxLength: 400 }}
              name="reason"
              style={{ width: 400 }}
              value={archiveReason}
              onChange={(e) => setArchiveReason(e.target.value)}
              placeholder="Reason"
              disabled={mutationArchiveSOP.isLoading}
            />
            <Typography
              style={{
                color: 'silver',
                textAlign: 'right',
              }}
            >
              {`${archiveReason?.length ?? 0}/400`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setFileToArchive('')
                setArchiveReason('')
              }}
              disabled={mutationArchiveSOP.isLoading}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!archiveReason || mutationArchiveSOP.isLoading}
              onClick={() => mutationArchiveSOP.mutate(fileToArchive)}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default CorporateSOP
