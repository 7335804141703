import styled from 'styled-components'
import { DialogTitle, withStyles, Tooltip } from '@material-ui/core'

export const DialogTitleStyled = styled(DialogTitle)`
  background-color: ${({ theme }): string => theme.palette.primary.main};
`

export const UploadItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[300]};
  .upload-item-info {
    display: flex;
    align-items: center;
  }
  .upload-item-icon {
    margin-right: 0.4rem;
  }
`
export const WidthFixedTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 180,
  },
}))(Tooltip)
