import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import axios from 'axios'
import { Content } from 'everchain-uilibrary'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import {
  getBusinessesQuery,
  getUserBusiness,
} from 'src/api/operations/get/business'
import SectionCard from 'src/components/SectionCard'
import { USER_ID } from 'src/configs/AuthService'
import { AuthContext } from 'src/context/AuthenticationContext'

const Dashboard: React.FC = () => {
  const [option, setOption] = useState('')
  const userToken = GetAccessToken()
  const { user } = useContext(AuthContext)
  const userId = user.profile[USER_ID]

  const [selectedAgent, setSelectedAgent] = useState<any>()
  const [selectedBusiness, setSelectedBusiness] = useState<any>()

  const [agents, setAgents] = useState<any>([])
  const [businesses, setBusinesses] = useState<any>([])

  const getUsersQuery = useQuery({
    enabled: agents.length <= 0,
    queryKey: ['getAgent'],
    queryFn: async () => {
      const result = await getUserBusiness(userId)
      const usersData = result.filter((t: any) => t.isCompliance)
      setAgents(usersData)
    },
  })

  const businessesQuery = useQuery({
    enabled: businesses.length <= 0,
    queryKey: ['getBusinesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(null)}`,
        },
      }).then((result: any) => {
        const businessesData = result.data.data.getBusinesses.businesses
        setBusinesses(businessesData)
      })
    },
  })

  const getCertificationKpi = useQuery({
    enabled: option === 'Certification',
    queryKey: ['getCertificationKpi', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/dashboard/getCertificationKpi`,
        params: {
          business: selectedBusiness?.id ?? null,
          businessType: selectedBusiness?.type ?? null,
          buyerType: selectedBusiness?.buyerType ?? null,
          servicerType: selectedBusiness?.servicerType ?? null,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const getAuditorKpi = useQuery({
    enabled: option === 'Auditor',
    queryKey: ['getAuditorKpi', selectedAgent],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/dashboard/getAuditorKpi`,
        params: {
          agent: selectedAgent?.id ?? null,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const auditorKpiData = getAuditorKpi?.data || []

  const certificationKpiData = getCertificationKpi?.data || []

  const cardClick = (cardType: string) => {}

  return (
    <Content>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Dashboard</Typography>
        </Grid>
      </Grid>
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={2} md={3}>
            <TextField
              data-cy="select-option"
              id="select-option"
              fullWidth
              select
              label="Option"
              name="result"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => setOption(e.target.value)}
              value={option || null}
            >
              <MenuItem value="">
                <em>Select an option</em>
              </MenuItem>

              <MenuItem value="Auditor" key="Auditor">
                Analyst
              </MenuItem>
              <MenuItem value="Certification" key="Certification">
                Certification
              </MenuItem>
            </TextField>
          </Grid>
          {option === 'Certification' && (
            <Grid item xs={12} lg={2} md={3}>
              {businessesQuery.isLoading ? (
                <Skeleton />
              ) : (
                <TextField
                  label="Business"
                  select
                  id="select-business"
                  value={
                    selectedBusiness
                      ? `${selectedBusiness.name} (${selectedBusiness.type})`
                      : ''
                  }
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      setSelectedBusiness(null)
                    }}
                  >
                    <em>Select business</em>
                  </MenuItem>

                  {businesses?.map((business: any) => {
                    return (
                      <MenuItem
                        key={business.id}
                        value={`${business.name} (${business.type})`}
                        onClick={() => {
                          setSelectedBusiness(business)
                        }}
                      >
                        {`${business.name} (${business.type})`}
                      </MenuItem>
                    )
                  })}
                </TextField>
              )}
            </Grid>
          )}

          {option === 'Auditor' && (
            <Grid item xs={12} lg={2} md={3}>
              {getUsersQuery.isLoading ? (
                <Skeleton variant="rect" width="100%" height={50} />
              ) : (
                <TextField
                  label="Analyst"
                  select
                  id="select-agent"
                  value={selectedAgent?.name ?? ''}
                  fullWidth
                  style={{ minWidth: '250px' }}
                >
                  <MenuItem
                    value=""
                    onClick={() => {
                      setSelectedAgent(null)
                    }}
                  >
                    <em>Select an analyst</em>
                  </MenuItem>
                  {agents?.map((u: any) => (
                    <MenuItem
                      value={u.name}
                      key={u.id}
                      onClick={() => {
                        setSelectedAgent(u)
                      }}
                    >
                      {u.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Grid>
          )}
        </Grid>
      </Box>

      <Box mt={10}>
        <Grid container>
          {option !== '' && (
            <Grid item xs={12} md={12}>
              <SectionCard
                cards={
                  option === 'Auditor' ? auditorKpiData : certificationKpiData
                }
                onCardClick={cardClick}
                selected=""
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </Content>
  )
}

export default Dashboard
