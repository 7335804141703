import styled, { createGlobalStyle, css } from 'styled-components'
import { Grid as KendoGrid } from '@progress/kendo-react-grid'
import { Colors } from 'everchain-uilibrary'
import { defaultFontFamily, defaultValueFontSize } from './themes/default'

interface KendoGridStyleProps {
  twoLinesHeader?: boolean
  primarycolor?: string
  secondarycolor?: string
  backgroundcolor?: string
  ispageable?: boolean
}

const borderRadius = '10px'
const fontSize = defaultValueFontSize
const border = `1px solid ${Colors.gray}`

const twoLinesHeaderProperties = css`
  .k-column-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    -webkit-box-align: center;
  }

  .k-link {
    height: 45px;
  }
`

const bottomRondedUnpagenable = css`
  .k-grid-content {
    border-radius: 0;
    border-bottom-left-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }
`

export const StyledKendoGrid = styled(KendoGrid)<KendoGridStyleProps>`
  ${({ twoLinesHeader }) => twoLinesHeader && twoLinesHeaderProperties}
  ${({ ispageable }) => !ispageable && bottomRondedUnpagenable}

  border-radius: ${borderRadius};

  .k-grid-header {
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
    color: ${(props) => props.backgroundcolor};
    background-color: ${(props) => props.primarycolor};
    border-top-left-radius: ${borderRadius};
    border-top-right-radius: ${borderRadius};
    border-bottom: ${border};
    padding-inline-end: var(--kendo-scrollbar-width, 17px);
  }

  .k-table-thead {
    background-color: transparent;
    color: ${(props) => props.backgroundcolor};
    font-size: ${fontSize};
  }

  .k-table-row {
    font-size: ${fontSize};
  }

  .k-grid-header:hover,
  .k-link:hover,
  .k-grid-header .k-sorted,
  .k-grid-header-menu,
  .k-grid-header-menu:hover,
  .k-grid-header .k-sort-icon {
    color: ${(props) => props.backgroundcolor};
  }

  .k-grid {
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    overflow: hidden;
  }

  .k-grid-header-menu {
    margin: 0 !important;
  }

  .k-grid-header-menu:focus {
    color: ${(props) => props.backgroundcolor} !important;
  }

  .k-grid-header th,
  .k-grid-content td {
    border-left: ${border};
  }

  .k-grid-header th {
    padding: 7px 25px 7px 10px;
    vertical-align: middle !important;
  }

  .k-table td {
    padding: 7px;
  }

  .k-cell-inner {
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .dropdown-country-wrap {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    white-space: nowrap;
  }

  .k-grid-header-wrap {
    border-radius: ${border} !important;
  }

  .k-grid-header-wrap > table {
    width: 100% !important;
  }

  .k-grid-content table {
    width: 100% !important;
  }

  tr:hover td {
    background-color: 'red' !important;
  }

  .k-pager {
    background-color: transparent;
    padding: 10px;
    box-sizing: border-box;
    outline: 0;
    white-space: nowrap;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    cursor: default;
    flex: 0 0 auto;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: ${Colors.black};
  }

  .k-grid.k-master-row: hover td {
    background-color: blue;
  }

  .k-table-row .k-master-row .k-table-alt-row .k-alt :hover {
    background-color: ${Colors.secondary};
  }

  .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover,
  .k-grid .k-table-tbody > .k-table-row:not(.k-detail-row).k-hover {
    background-color: coral;
  }

  .k-grid.k-table-row: hover {
    border-color: ${Colors.secondary};
  }

  .k-grid .k-table-tbody .k-table-row.k-hover {
    background-color: coral;
  }

  .k-pager-numbers .k-button:hover {
    color: ${Colors.blue};
    background-color: ${Colors.secondary};
  }
  .k-pager-numbers .k-button {
    color: ${Colors.blue};
  }

  .k-button-flat-primary {
    color: ${Colors.blue};
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
  }

  .k-dropdownlist {
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${Colors.darkGray};
    margin-right: 5px;
    color: ${Colors.black};
    background-color: transparent;
    font-size: ${fontSize};
    width: 90px !important;
  }

  .k-pager-sizes,
  .k-pager-info {
    color: ${Colors.darkGray};
    font-size: ${fontSize};
    font-family: ${defaultFontFamily};
  }

  .k-input-inner {
    width: 70px;
  }

  span.k-svg-icon.k-svg-i-more-vertical {
    color: ${Colors.white};
  }

  span.k-icon.k-i-more-vertical {
    color: ${Colors.white};
    margin-top: -9px;
  }
  span.k-icon.k-i-sort-desc-sm {
    color: ${Colors.white};
  }

  .k-icon k-i-sort-asc-sm {
    color: ${Colors.white};
  }

  .k-icon k-i-sort-desc-sm {
    color: ${Colors.white};
  }

  .k-input-button {
    width: 25px;
  }
`

export default createGlobalStyle`
    html, body {
        font-size: 0.9rem;
    }
    body {
      line-height: 1rem;
      text-transform: none;
      font-family: 'Roboto', sans-serif;
      font-size: 0.9rem;
    }
    #root {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    @media(min-width: 768px) {
      html {
        font-size: 0.9rem;
      }
    }
    @media(min-width: 1281px) {
      html {
        font-size: 0.9rem;
      }
    }
    .k-grid { line-height: 1 }

    .acceptBidButton:hover
    {
      color: ${Colors.primary};
    }
    th.k-header.active > div > div {
      color: ${Colors.white};
      background-color: ${Colors.primary};
    }

    .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-desc-sm, .k-grid-header .k-header > .k-link > .k-svg-icon.k-i-sort-asc-sm {
      color: ${Colors.white};
    }

    .k-grid-header {
      color: ${Colors.white};
      background-color: ${Colors.primary};
      }
      .k-grid-header .k-column-title :hover{
      color: ${Colors.white};
      }
      .k-grid-header .k-header * .k-link {
        color: ${Colors.white};
        font-size: 0.9rem;
        padding: 7px 18px 7px 8px;
        line-height: 1.5rem;
        font-weight: 500;
        letter-spacing: 0.01071em;
      }
      .k-grid-header .k-header * .k-link .k-svg-icon { 
          color: ${Colors.white};
      }
      .k-grid-header th,
      .k-grid-content td,
      .k-grid .k-table-td {
        border-left: 1px solid ${Colors.gray};
      }
      tr:hover td {
        background-color: ${Colors.secondary};
      }
      .k-grid td {
        padding: 10px 16px 7px 8px;
        //padding: 10px 16px ;
        font-size: 0.9rem;
        line-height: 1.3;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      }
      /* .k-grid tr.k-alt {
        //background-color: rgb(255, 255, 255,3);
      } */
      .k-grid-header-wrap > table {
        width: 100% !important;
      }
      .k-grid-content table {
        width: 100% !important;
      }
      .k-svg-icon {
        width: 14px;
        height: 14px;
      }
      span.k-svg-icon.k-svg-i-more-vertical {
        color: ${Colors.white};
      }
      span.k-svg-icon.k-i-sort-desc-sm{
        color: ${Colors.white};
      }
      .k-text-right {
        text-align: right;
      }
      th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeSmall {
        padding: 8px 6px 8px 6px;
      }    
      .MuiTableCell-sizeSmall {
        padding: 8px 7px 8px 7px;
      }

      .k-table td {
        padding: 7px;
      }
  
      .k-table-thead {
        background-color: transparent;
        color: ${Colors.white};
        font-size: 13px;
      }
  
      .k-table-row {
        font-size: 13px;
      }
  
      .k-cell-inner {
        font-weight: 500;
        letter-spacing: 0.02em;
      }
     .k-grid-header th {
        padding: 7px 25px 7px 10px !important;
        vertical-align: middle !important;
      }
      .k-grid-header:hover,
        .k-grid-header .k-sorted,
        .k-grid-header-menu,
        .k-grid-header .k-sort-icon {
          color: ${Colors.white};
      }     
  
      .k-sort-icon .k-svg-icon{
        margin-top: -2px;
      }
  
      .k-grid-header-menu {
        margin: 0 !important;
      }
  
      .k-grid-header-menu:focus {
        color: ${Colors.white} !important;
      }
  
      .k-grid tbody tr:hover {
      background: ${Colors.secondary};
      }
  
      .k-detail-row {
        background: ${Colors.white} !important;
      } 
  
      .k-grid .k-detail-row .k-detail-cell {
        padding: 0;
      }
  
      .k-detail-row .k-grid-header tr:hover  {
        background: ${Colors.primary};
      } 
  
     .k-pager-numbers .k-button:hover {
        color: ${Colors.blue};
        background-color: ${Colors.secondary};
      }
      .k-pager-numbers .k-button {
        color: ${Colors.blue};
      }
    .k-animation-container {
        z-index: 10003 !important;        
        &.k-animation-container-relative {
          overflow: initial;
        }       
      }

      .popup-content {    
        border-radius: 4px;      
       }

      .wrapper-content {
        box-shadow: 0px 1px 1px  rgb(235 235 235), 1px 2px 3px  rgb(240 240 240), 2px 2px 3px  rgb(245 245 245);
        border-radius: 4px;   
      }


    .k-grid-header-wrap ,
    .k-grid-header-wrap  th.k-header {
      background: ${Colors.primary};
      color: ${Colors.white};
      .k-button:hover {
        color: #f3fafe; 
      }
    }
    .k-grid tbody tr:hover {
    background: ${Colors.secondary};
    }
    .k-pager-numbers .k-button:hover {
      color: ${Colors.primary};
      background-color: ${Colors.secondary};
    }
    .k-pager-numbers .k-button {
      color: ${Colors.primary};
    }
    .k-pager-numbers .k-button.k-selected {
      color: ${Colors.white};
      background-color: ${Colors.blue} !important;
    }
    .k-picker-solid {
      background-color: ${Colors.white};
    }
   .MuiPaginationItem-textPrimary.Mui-selected {
      color: ${Colors.white};
      background-color: ${Colors.blue};
    }
    .MuiList-root.MuiList-padding {
      padding-top: 0px;
    }
    .MuiMenu-list {
      max-height: 325px;
    }
    .MuiListItem-root {
      width:auto;    
    }
    .logo-sidebar {
    margin-right: 25px;
    }
    .MuiFormControl-marginNormal {
      width: 100%;
    }
    .MuiListItemIcon-root {
      color: ${Colors.primary};
    }

    .k-svg-i-more-vertical {
      padding-top: 0px
    }
    .k-grid-header th {
      padding: 7px 25px 7px 10px;
      vertical-align: middle !important;
    }

    .k-loading-mask {
        color: ${Colors.primary};
    }
    .MuiTypography-root .MuiListItemText-primary .MuiTypography-body2 .MuiTypography-displayBlock {
      color: ${Colors.primary};
    }

   .MuiTypography-root .MuiTypography-caption .MuiTypography-colorTextPrimary {
    color: ${Colors.black}; 
   }

    .MuiTypography-caption {
     color: ${Colors.black} !important;  
    }   

    .MuiTabs-flexContainer {
     justify-content: space-around;
    }

    .MuiInputLabel-root {
     width: 100%;
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.82) !important;        
        line-height:16px;
    }

  
   .MuiOutlinedInput-notchedOutline legend {
    font-size: 0.82em;
    }
}
`
