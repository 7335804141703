import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  ListItemIcon,
  Box,
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from 'notistack'
import { notistackOptions } from 'src/configs/notistackOptions'
import { IQuestion } from 'src/api/models'
import { BACKEND_URL } from 'src/api/axios/api'
import Close from '@material-ui/icons/Close'
import Delete from '@material-ui/icons/Delete'

import { GetAccessToken } from 'src/api/axios/helper'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Skeleton from '@material-ui/lab/Skeleton'
import MultipleSelectionDropDown from 'src/components/MultipleSelectionDropDown'
import { DialogTitleStyled } from '../Dialogs/style'

interface DocumentQuestionProps {
  open: boolean
  close: () => void
  setQuestionsSelected: (values: any[]) => void
  // eslint-disable-next-line react/require-default-props
  id?: number
}

interface IQuestions {
  question: number[]
}

const DocumentQuestion: React.FC<DocumentQuestionProps> = ({
  open,
  close,
  setQuestionsSelected,
  id,
}: DocumentQuestionProps) => {
  const { enqueueSnackbar } = useSnackbar()

  const notistackSucces = notistackOptions('success')
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [questionSelected, setQuestionSelected] = useState<any[]>([])
  const [initialValues, setInitialValues] = useState<IQuestions>({
    question: [],
  })
  const userToken = GetAccessToken()

  const onCloseDialog = () => close()

  useEffect(() => {
    setOpenDialog(open)
  }, [open])

  const questionDataQuery = useQuery({
    queryKey: ['getAllQuestion'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/question/getall`,
        params: { status: true },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data.questions
      })
    },
  })
  const questionList: IQuestion[] = questionDataQuery?.data

  const documentQuestionDataQuery = useQuery({
    queryKey: ['getDocumentQuestions'],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/question/getDocumentQuestion`,
        params: {
          documentId: id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data.questions
      })
    },
    onSuccess: (data) => {
      setInitialValues({
        question: data.map((x: any) => x.id),
      })
    },
    enabled: id !== undefined && id !== null,
    cacheTime: 0,
  })

  const documentQuestionsData = documentQuestionDataQuery?.data || []

  const inputDataFormSchema = Yup.object().shape({
    jurisdiction: Yup.array().min(1, 'Required'),
  })

  const getAvailableQuestions = () => {
    const available = questionList
      ?.filter(
        (item) =>
          documentQuestionsData &&
          !documentQuestionsData?.some((x: any) => x.id === item.id)
      )
      .map((x) => {
        return {
          id: x.id,
          statement: x.statement,
        }
      })

    return available
  }

  const getQuestionsSelected = () => {
    const selectedList = questionList?.filter(
      (j) =>
        documentQuestionsData &&
        documentQuestionsData?.some((x: any) => j.id === x.id)
    )

    const dataList = selectedList?.map((j) => {
      const found =
        documentQuestionsData &&
        documentQuestionsData?.find((x: any) => j.id === x.id)

      return {
        id: found.id,
        statement: found.statement,
      }
    })

    return dataList
  }

  const inputDataForm = useFormik({
    initialValues,
    validationSchema: inputDataFormSchema,
    onSubmit: (values, { setSubmitting }) => {
      setQuestionsSelected(questionSelected)
      setQuestionSelected([])
      close()
    },
  })
  const queryClient = useQueryClient()
  const deleteDocumentQuestionMutation = useMutation({
    mutationFn: async (questionId: number) => {
      await axios({
        method: 'delete',
        headers: { Authorization: userToken },
        params: { questionId, documentId: id },
        url: `${BACKEND_URL}/question/deleteDocumentQuestion`,
      })
    },
    onSuccess: async () => {
      enqueueSnackbar('Question removed', notistackSucces)
      await queryClient.refetchQueries({ queryKey: 'getDocumentQuestions' })
    },
  })

  const handleDeleteQuestion = (questionId: number) => {
    deleteDocumentQuestionMutation.mutate(questionId)
  }

  const renderQuestion = () => {
    const handleChange = (value: any) => {
      setQuestionSelected(value)
      inputDataForm.setFieldValue('question', value)
    }

    const data = questionList ? getAvailableQuestions() : []

    return (
      <MultipleSelectionDropDown
        id="input-Question"
        label="Question"
        data={data}
        disable={false}
        selectionState={questionSelected}
        handleSelectionChange={handleChange}
        displayProperties={['statement']}
      />
    )
  }

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image" />
      <div className="k-loading-color" />
    </div>
  )

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
        maxWidth="md"
      >
        <DialogTitleStyled id="confirm-dialog-title">
          <Typography
            variant="body1"
            style={{
              color: 'white',
              fontSize: '1.5rem',
              fontWeight: 400,
              lineHeight: 1.2,
              textAlign: 'center',
            }}
          >
            Question
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onCloseDialog}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </DialogTitleStyled>
        <DialogContent>
          <Grid container direction="column" spacing={3}>
            {deleteDocumentQuestionMutation.isLoading && loadingPanel}
            <Grid item>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  style={{ width: '380px' }}
                >
                  {questionDataQuery.isLoading && (
                    <Skeleton height={35} width={350} />
                  )}
                  {questionList && renderQuestion()}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <form onSubmit={inputDataForm.handleSubmit}>
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      autoFocus
                      disabled={documentQuestionDataQuery.isLoading}
                    >
                      Add
                    </Button>
                  </form>
                </Box>
              </Box>
            </Grid>
            <Grid item>
              <Typography variant="h6" component="div" color="primary">
                Submitted
              </Typography>
              <List
                dense={true}
                style={{
                  minHeight: '400px',
                  maxHeight: '100%',
                  overflow: 'auto',
                  width: '450px',
                  border: '1px solid lightgrey',
                }}
              >
                {documentQuestionDataQuery.isLoading && (
                  <>
                    {[0, 1, 2, 3, 4, 5].map((x) => (
                      <ListItem key={x}>
                        <Skeleton height={35} width={380} />
                      </ListItem>
                    ))}
                  </>
                )}
                {getQuestionsSelected()?.map((x: any) => (
                  <ListItem key={x.id}>
                    <ListItemText primary={x.statement} />
                    <ListItemIcon
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeleteQuestion(x.id)}
                    >
                      <Delete />
                    </ListItemIcon>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default DocumentQuestion
