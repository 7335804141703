import { Colors } from 'everchain-uilibrary'
import styled from 'styled-components'

interface StepNumberProps {
  active: boolean
}

export const StepNumber = styled.div<StepNumberProps>`
  font-size: 0.8rem;
  background-color: ${({ active }): string =>
    active ? Colors.primary : Colors.gray};
  transition: all 100ms ease;
  color: ${Colors.white};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`

export const StepContent = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  margin-right: 0.8rem;

  @media (min-width: 725px) {
    justify-content: center;
  }
`
export const Step = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
`

export const ButtonGroup = styled.div`
  & > *:not(:last-child) {
    margin-right: 1.2rem;
  }
`
