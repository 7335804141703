import { Grid, makeStyles, Typography } from '@material-ui/core'
import { Colors } from 'everchain-uilibrary'
import React, { useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ICategory } from 'src/api/models'
import {
  DOCUMENT_REGISTRATION,
  DOCUMENT_REPOSITORY,
  THIRD_PARTY_REPOSITORY,
} from 'src/routes'

interface CategoriesTabsProps {
  categoryData: ICategory[]
  origin: string
}

const CategoriesTabs: React.FC<CategoriesTabsProps> = ({
  categoryData,
  origin,
}: CategoriesTabsProps) => {
  const [categorySelected, setCategorySelected] = React.useState<number>(0)
  const history = useHistory()
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const categoryId = query.get('category')

  useEffect(() => {
    if (categoryData.length > 0) {
      setCategorySelected(categoryData[0].id)
      if (origin === 'registration')
        history.push(`${DOCUMENT_REGISTRATION}?category=${categoryData[0].id}`)
      else if (origin === 'repository')
        history.push(`${DOCUMENT_REPOSITORY}?category=${categoryData[0].id}`)
      else if (origin === 'thirdPartyRepo')
        history.push(`${THIRD_PARTY_REPOSITORY}?category=${categoryData[0].id}`)
    }
  }, [categoryData, history, origin])

  useEffect(() => {
    if (categoryId) setCategorySelected(Number(categoryId))
  }, [categoryId])

  const handleCategorySelected = (newValue: number) => {
    setCategorySelected(newValue)
  }

  const useStyles = makeStyles({
    root: () => ({
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#F4F4F4',
      },
    }),
    selected: () => ({
      borderBottom: `2px solid ${Colors.darkBlue}`,
      '&:hover': {
        backgroundColor: '#F4F4F4',
      },
    }),
    buttonSelected: () => ({
      color: Colors.darkBlue,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '0.85rem',
    }),
    button: () => ({
      color: '#707070',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '0.85rem',
    }),
  })

  const classes = useStyles()

  if (categoryData.length === 0) return <></>

  return (
    <>
      <Grid container spacing={5}>
        {categoryData
          ?.sort((a, b) => a.order - b.order)
          ?.map((category: ICategory) => {
            return (
              <Grid
                item
                key={category.id}
                className={
                  categorySelected === category.id
                    ? classes.selected
                    : classes.root
                }
                onClick={() => {
                  handleCategorySelected(category.id)
                  if (origin === 'registration')
                    history.push(
                      `${DOCUMENT_REGISTRATION}?category=${category.id}`
                    )
                  else if (origin === 'repository')
                    history.push(
                      `${DOCUMENT_REPOSITORY}?category=${category.id}`
                    )
                  else
                    history.push(
                      `${THIRD_PARTY_REPOSITORY}?category=${category.id}`
                    )
                }}
              >
                <Typography
                  className={
                    categorySelected === category.id
                      ? classes.buttonSelected
                      : classes.button
                  }
                >
                  {category.name}
                </Typography>
              </Grid>
            )
          })}
      </Grid>
    </>
  )
}

export default CategoriesTabs
