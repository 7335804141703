import { Typography } from '@material-ui/core'
import { Colors } from 'everchain-uilibrary'
import styled from 'styled-components'

export const SectionColumn = styled.section`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  padding: ${({ theme }): string =>
    `${theme.spacing(5)}px ${theme.spacing(5)}px`};
  background-color: white;
`

export const ContainerFluid = styled.div`
  padding-right: ${({ theme }): string => `${theme.spacing(5)}px`};
  padding-left: ${({ theme }): string => `${theme.spacing(5)}px`};
`

interface DTSummaryGridProps {
  columns?: number
}

export const DTSummaryGrid = styled.div<DTSummaryGridProps>`
  display: grid;
  row-gap: 0.5rem;
  grid-auto-rows: max-content;
  grid-template-columns: repeat(${({ columns }) => columns ?? 3}, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  padding-left: 0.8rem;
`
export const DTSummaryGridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .list-item--text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .MuiListItemText-primary {
      font-size: 0.78rem;
      font-weight: 500;
    }
    .MuiListItemText-secondary {
      font-size: 0.82rem;
    }
  }
`
export const DTSummaryGridHeader = styled(Typography)`
  font-size: 0.75rem;
  text-transform: uppercase;
  background-color: #f3fcff;
  font-weight: 600;
  color: ${Colors.black};
  width: 100%;
  line-height: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`
export const DTSummaryGridValue = styled(Typography)`
  font-size: 0.8rem;
  font-weight: 300;
  background-color: white;
  color: #424242;
  width: 100%;
  line-height: 2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.3rem;
`
export const DTSummaryGridContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const DTSummaryBlockItem = styled.div`
  background-color: ${({ theme }): string => theme.palette.common.black};
  padding: 0.8rem;
  text-align: center;
  height: 5rem;
`
export const DTSummaryBlockItemRow = styled.div`
  background-color: ${({ theme }): string => theme.palette.common.black};
  padding: 0.8rem;
  text-align: center;
  height: 5rem;
  display: flex;
  flex-direction: row;
  align-content: center;
`
export const DTSummaryBlocks = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }): string => theme.palette.common.white};
  ${DTSummaryBlockItem} {
    margin-bottom: 0.5rem;
  }
  ${DTSummaryBlockItemRow} {
    margin-bottom: 0.5rem;
  }
  ${DTSummaryBlockItemRow}:last-child {
    margin-left: 0.5rem;
  }
  ${DTSummaryBlockItem}:only-child:last-child {
    margin-left: 0.5rem;
  }
  ${DTSummaryBlockItem}:last-child {
    margin-bottom: 0rem;
  }
  min-width: 9rem;
`
export const DTSummaryBlockItemValue = styled(Typography)`
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: ${Colors.white};
  text-align: center;
  margin-top: 7px;
`
export const DTSummaryBlockItemHeader = styled(Typography)`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.3rem;
  color: ${Colors.white};
  text-align: center;
`
