import React from 'react'
import { Box, LinearProgress } from '@material-ui/core'
import { EverChainLogoSquare } from 'everchain-uilibrary'

const LoaderPage = () => {
  return (
    <Box width="100%">
      <LinearProgress />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <EverChainLogoSquare width={400} />
      </Box>
    </Box>
  )
}

export default LoaderPage
