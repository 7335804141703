import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {
  CssBaseline,
  unstable_createMuiStrictModeTheme,
  MuiThemeProvider,
  responsiveFontSizes,
  IconButton,
} from '@material-ui/core'

import { ThemeProvider as Mui5ThemeProvider } from '@mui/material'

import { makeStyles } from '@material-ui/core/styles'
import { MaterialDesignContent, SnackbarProvider, useSnackbar } from 'notistack'
import { BrowserRouter as Router } from 'react-router-dom'

import DateFnsUtils from '@date-io/date-fns'
import enLocale from 'date-fns/locale/en-US'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import { GlobalStyle, defaultTheme } from 'src/styles'

import { QueryClient, QueryClientProvider } from 'react-query'
import {
  QueryClient as NewQueryClient,
  QueryClientProvider as NewQueryClientProvider,
} from '@tanstack/react-query'
import { Close } from '@material-ui/icons'
import { Colors } from 'everchain-uilibrary'
import IdleTimeout from './components/IdleTimeout'
import SessionExpiringModal from './components/SessionTimeout'
import App from './App'
import { AuthProvider } from './context/AuthenticationContext'

const localeMap = {
  en: enLocale,
}

const locale = 'en'

const createMuiTheme = unstable_createMuiStrictModeTheme

const theme = createMuiTheme(defaultTheme)
const themeResponsiveFonts = responsiveFontSizes(theme)

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
const useStyle = makeStyles({
  success: { backgroundColor: `${Colors.blue} !important` },
  error: { backgroundColor: 'blue' },
  warning: { backgroundColor: 'green' },
  info: { backgroundColor: 'yellow' },
  root: {
    top: 75,
  },
})

const DismissAction = ({ id }: any) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton key="close" color="inherit" onClick={() => closeSnackbar(id)}>
      <Close fontSize="small" />
    </IconButton>
  )
}

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: Colors.blue,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: Colors.error,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: Colors.warning,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: Colors.info,
  },
}))

const Root: React.FC = () => {
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  const newQueryClient = new NewQueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: twentyFourHoursInMs,
      },
    },
  })

  return (
    <NewQueryClientProvider client={newQueryClient}>
      <QueryClientProvider client={queryClient}>
        <MuiThemeProvider theme={themeResponsiveFonts}>
          <Mui5ThemeProvider theme={themeResponsiveFonts}>
            <ThemeProvider theme={themeResponsiveFonts}>
              <CssBaseline />
              <GlobalStyle />
              <Router>
                <SnackbarProvider
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  hideIconVariant={true}
                  action={(key) => <DismissAction id={key} />}
                  Components={{
                    success: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                    warning: StyledMaterialDesignContent,
                    info: StyledMaterialDesignContent,
                  }}
                >
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={localeMap[locale]}
                  >
                    <SessionExpiringModal>
                      <IdleTimeout>
                        <AuthProvider>
                          <App />
                        </AuthProvider>
                      </IdleTimeout>
                    </SessionExpiringModal>
                  </MuiPickersUtilsProvider>
                </SnackbarProvider>
              </Router>
            </ThemeProvider>
          </Mui5ThemeProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </NewQueryClientProvider>
  )
}

export default Root
