import { Box, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import { State } from '@progress/kendo-data-query'
import axios from 'axios'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Query, useQuery, useQueryClient } from 'react-query'
import { BACKEND_URL, GetAccessToken } from 'src/api/axios/api'
import { CertificationEventData } from 'src/api/models/Common'
import { renderDateTime } from 'src/utils/formatKendoColumns'
import { ColumnMenu } from 'src/components/Filters/HeaderFilter'
import { GridColumn as KendoGridColumn } from '@progress/kendo-react-grid'
import { StyledKendoGrid as KendoGrid } from 'src/styles/kendoGridStyle'
import { AuthContext } from 'src/context/AuthenticationContext'
import { getBusinessesQuery } from 'src/api/operations/get/business'
import { ICertificationPeriod } from 'src/api/models'
import Skeleton from '@material-ui/lab/Skeleton'
import Loader from 'src/components/Loader'
import { Content } from 'everchain-uilibrary'

const CertificationEvents: React.FC = () => {
  const grid = useRef<any>(null)
  const [certificationData, setCertificationData] =
    useState<CertificationEventData>()
  const userToken = GetAccessToken()
  const [gridState, setGridState] = useState<State>({
    skip: 0,
    take: 25,
    filter: undefined,
    sort: undefined,
  })
  const queryClient = useQueryClient()
  const columns: any[] = [
    {
      field: 'dateCreated',
      title: 'Date',
      show: true,
      cell: renderDateTime,
      width: '150px',
    },
    { field: 'business', title: 'Business', show: true, width: '200px' },
    { field: 'description', title: 'Description', show: true, width: '300px' },
    {
      field: 'additionalData',
      title: 'Additional Data',
      show: true,
      width: '300px',
    },
    { field: 'user', title: 'User', show: true, width: '200px' },
  ]
  const [selectedBusiness, setSelectedBusiness] = useState<any>()
  const { profileClient } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [selectedCertificationPeriod, setSelectedCertificationPeriod] =
    useState<ICertificationPeriod>({ id: null, certificationDate: 'Current' })
  const businessesQuery = useQuery({
    queryKey: ['businesses'],
    queryFn: async () => {
      return axios({
        url: process.env.REACT_APP_CONTROLPANEL,
        method: 'post',
        headers: {
          Authorization: userToken,
        },
        data: {
          query: `${getBusinessesQuery(
            profileClient ? profileClient.Id : null
          )}`,
        },
      }).then((result: any) => {
        const businessesData = result.data.data.getBusinesses.businesses
        return businessesData
      })
    },
  })

  const businessesData = businessesQuery?.data

  useQuery({
    enabled: !!selectedBusiness && !!selectedCertificationPeriod,
    queryKey: [
      'GetCerficationEvents',
      gridState,
      selectedBusiness,
      selectedCertificationPeriod,
    ],
    queryFn: async () => {
      axios({
        method: 'get',
        url: `${BACKEND_URL}/Audit/GetCerficationEvents`,
        params: {
          pagination: encodeURIComponent(JSON.stringify(gridState)),
          business: selectedBusiness?.id,
          certificationPeriod: selectedCertificationPeriod.id,
        },
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        setCertificationData(result.data)
        setLoading(false)
      })
    },
    cacheTime: 0,
  })

  useEffect(() => {
    if (businessesData) setSelectedBusiness(businessesData[0])
  }, [businessesData])

  const handleSelectedBusiness = (business: any) => {
    setSelectedBusiness(business)

    // Removing all queries from the cache when a business change
    // so the data is loaded again for the selected business
    queryClient.removeQueries({
      predicate: (cachedQuery: Query) => {
        if (cachedQuery.queryKey[0] === 'businesses') return false

        return true
      },
    })

    setSelectedCertificationPeriod({ id: null, certificationDate: 'Current' })
  }

  const getCertificationQuery = useQuery({
    enabled: !!selectedBusiness,
    queryKey: ['getCertificationQuery', selectedBusiness],
    queryFn: async () => {
      return axios({
        method: 'get',
        url: `${BACKEND_URL}/actions/certificationperiod/${selectedBusiness.id}`,
        headers: {
          Authorization: userToken,
        },
      }).then((result: any) => {
        return result.data
      })
    },
  })

  const certificationPeriodsData: ICertificationPeriod[] =
    getCertificationQuery?.data

  const handleCertificationPeriodChange = (
    certificationPeriod: ICertificationPeriod
  ) => {
    setSelectedCertificationPeriod(certificationPeriod)
    // Removing all queries from the cache when a business change
    // so the data is loaded again for the selected business
    queryClient.removeQueries({
      predicate: (cachedQuery: Query) => {
        if (
          cachedQuery.queryKey[0] === 'businesses' ||
          cachedQuery.queryKey[0] === 'getCertificationQuery'
        )
          return false

        return true
      },
    })
  }

  return (
    <Content>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="subtitle1">Certification Events</Typography>
        </Grid>
      </Grid>
      <Box
        style={{ width: '100%' }}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex">
          {!businessesQuery.isLoading ? (
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
              }}
            >
              <Box display="flex" alignItems="center" flexDirection="column">
                <Box
                  ml={3}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  {businessesQuery?.data?.length > 1 ? (
                    <TextField
                      select
                      id="select-business"
                      value={
                        selectedBusiness
                          ? `${selectedBusiness.name}-${selectedBusiness.type}`
                          : ''
                      }
                      fullWidth
                      label="Business"
                      style={{ minWidth: '250px' }}
                    >
                      <MenuItem value="" disabled={true}>
                        <em>Select business</em>
                      </MenuItem>

                      {businessesQuery.data.map((business: any) => {
                        return (
                          <MenuItem
                            key={business.id}
                            value={`${business.name}-${business.type}`}
                            onClick={() => {
                              handleSelectedBusiness(business)
                            }}
                          >
                            {`${business.name} - ${business.type}`}
                          </MenuItem>
                        )
                      })}
                    </TextField>
                  ) : (
                    <Typography
                      id="select-business"
                      variant="h2"
                      style={{ fontWeight: 'bold' }}
                    >
                      {selectedBusiness?.name ?? ''}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>
          ) : (
            <Skeleton variant="rect" width={250} />
          )}

          {!(getCertificationQuery.isLoading && businessesQuery.isLoading) ? (
            <Grid
              item
              style={{
                marginLeft: 10,
                display: 'flex',
                alignItems: 'center',
                verticalAlign: 'center',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <TextField
                    select
                    id="select-certification-date"
                    value={selectedCertificationPeriod.certificationDate}
                    style={{ minWidth: '115px' }}
                    label="Certification Period"
                  >
                    <MenuItem
                      value="Current"
                      onClick={() => {
                        handleCertificationPeriodChange({
                          id: null,
                          certificationDate: 'Current',
                        })
                      }}
                    >
                      Current
                    </MenuItem>

                    {certificationPeriodsData?.map(
                      (item: ICertificationPeriod) => {
                        return (
                          <MenuItem
                            key={item.id}
                            value={item.certificationDate}
                            onClick={() => {
                              handleCertificationPeriodChange(item)
                            }}
                          >
                            {item.certificationDate}
                          </MenuItem>
                        )
                      }
                    )}
                  </TextField>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Skeleton variant="rect" width={250} />
          )}
        </Box>
      </Box>
      <Box mt={2}>
        <Box>
          {loading && <Loader />}
          <KendoGrid
            ref={grid}
            scrollable="scrollable"
            data={certificationData?.certificationEvents ?? []}
            skip={gridState.skip}
            take={gridState.take}
            pageSize={gridState.take}
            filter={gridState.filter}
            sort={gridState.sort}
            sortable
            pageable={{ pageSizes: [5, 10, 25, 50, 100] }}
            total={certificationData?.total ?? 0}
            onDataStateChange={(e) => {
              setGridState(e.dataState)
            }}
            style={{ height: '600px' }}
          >
            {columns.reduce((acc, item, idx) => {
              const component = [...acc]

              component.push(
                <KendoGridColumn
                  key={item.field + item.title}
                  field={item.field}
                  title={item.title}
                  cell={item.cell}
                  width={item.width}
                  columnMenu={ColumnMenu}
                />
              )

              return component
            }, [])}
          </KendoGrid>
        </Box>
      </Box>
    </Content>
  )
}

export default CertificationEvents
